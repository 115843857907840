import { OktaAuth } from '@okta/okta-auth-js';
const OKTA_CLIENT_ID = process.env.NODE_ENV === 'production' ? '0oaj79m13d9ghzaMc697' : '0oaihbj3hcabyTzGU697';
const oktaAuth = new OktaAuth({
  issuer: process.env.OKTA_ISSUER || 'https://tenantinc.okta.com/',
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true // Enable PKCE for enhanced security
});
export default oktaAuth;
